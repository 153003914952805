<template>
  <div class="settings">
    <div  v-if="reRender" class="content">
      <loader v-if="!pageLoaded"/>
      <div v-else style="margin-top: 30px; margin-left: 55px;">
        <div class="tabs">
          <div @click="tab = 'detail'" :class="tab === 'detail' ? 'tab-bar-active' : 'tab-bar'" style="border-top-left-radius: 5px;">
            <p class="text">Sipariş Detayları</p>
          </div>
          <div @click="tab = 'support'" :class="tab === 'support' ? 'tab-bar-active' : 'tab-bar'" style="border-top-right-radius: 5px;">
            <p class="text">Çözüm Merkezi</p>
          </div>
        </div>
        <div v-show="tab === 'support'" style="margin-top: 56px;  text-align: center; width: 100%;">
          <div v-if="order && order.gig_cat_id && order.gig_cat_id === 6" class="res-center-head-box" style="height:auto; padding-bottom: 30px;">
            <div class="icon-area">
              <img src="https://gcdn.bionluk.com/site/cicons/ic-solution-center.svg" onload="SVGInject(this)" class="resolution-center-icon">
            </div>
            <div>
              <div class="text1">
                Önemli Not
              </div>
              <div class="text2" style="text-align: left;  max-width:600px;">
                Siparişlerinin sorunsuz tamamlanabilmesi için bazı konulara değinmek isteriz.
                <br>
                <br>1- Alıcıların admin kullanıcı adı ve parola bilgilerini freelancer’dan teslim aldıktan hemen sonra değiştirmeleri
                <br>2- Alıcıların siparişe ait tüm kaynak kodları ve dosyaları freelancer’la karşılıklı anlaşarak temin etmeleri
                <br>3- Alıcıların websitelerinin hosting durumu ve süresi hakkında freelancer’la net bir anlaşmaya varmaları
                <br><br>Bu maddeler tamamlanmadığı takdirde freelancer’ın sipariş tamamlanmış dahi olsa ilgili sipariş üstünde değişikliklik yapma yetkisi bulunabileceğini hatırlatmak isteriz.
                <br><br>Yukarıda belirtilen bilgiler talep edilmediği ve konuyla ilgili gerekli tedbirler alınmadığı durumlarda siparişle ilgili yaşanan aksaklıklarda Bionluk.com sorumluluk kabul etmemektedir.
                <br>Çok sevgiler.
              </div>

            </div>
          </div>
          <div id="select-support-category" class="res-center-head-box">
            <div class="icon-area">
              <img src="https://gcdn.bionluk.com/site/cicons/ic-solution-center.svg" onload="SVGInject(this)" class="resolution-center-icon">
            </div>
            <div>
              <div class="text1">
                Çözüm Merkezi’ne Hoş Geldin!
              </div>
              <div class="text2">
                Siparişinle ilgili sorun yaşadığını düşünüyorsan bu alanı kullanabilirsin. Bu alanda yazılan mesajlar hem bizim tarafımızdan hem de {{order.kim.kimTitle}} tarafından görüntülenmektedir.
              </div>
            </div>
          </div>
          <p class="datetime">Bionluk, {{ order.payed_atb | customDateFormat('dd MMMM, yyyy - HH:mm') }}</p>
          <div v-if="ticketMessages.length === 0" class="res-center-center">
            <template v-if="orderSupportWizardStep === 1">
              <h4 class="text1">Sana nasıl yardımcı olabiliriz?</h4>
              <div v-for="(supportItem, sI) in orderSupportMainCategories" class="row-center">
                <input :value="supportItem.id" v-model="selectedSupportCategoryId" class="big-radio" type="radio" :id="'sorun-'+sI"> <label :for="'sorun-'+sI">{{supportItem.title}}</label>
              </div>

              <template v-if="selectedSupportCategoryId">
                <div style="font-size: 14px; line-height: 1.29; color: #2d3640; font-weight: 500; margin-left: 60px; margin-top: 10px; max-width: 500px;" v-if="orderSupportCancelContinueText">
                  {{orderSupportCancelContinueText}}
                </div>
                <template v-else>
                  <p class="text1" style="margin-top: 0; padding-top: 20px">Biraz daha detay verebilir misin?</p>
                  <div v-for="(supportSubItem, sI2) in orderSupportSubCategories" class="row-center">
                    <input :value="supportSubItem.id" v-model="selectedSupportSubCategoryId" class="big-radio" type="radio" :id="'sorunSub-'+sI2"> <label :for="'sorunSub-'+sI2">{{supportSubItem.title}}</label>
                    <input v-if="supportSubItem.id === selectedSupportSubCategoryId && supportSubItem.title === 'Diğer'"  v-model="orderSupportTicketTitle"  maxlength="50" style="width:350px; margin-left:15px;" placeholder="Lütfen konu başlığı belirt...">
                  </div>
                </template>
              </template>

              <div style="display: flex; align-items: center; justify-content: center; margin-top: 60px; margin-bottom: 10px;">
                <button @click="orderSupportWizardStep = 2" :disabled="computeDisabledForSupportTicket" class="cuper-green-button">Devam Et</button>
              </div>
            </template>

            <template v-if="orderSupportWizardStep === 2">
              <div v-if="selectedSupportSubCategoryId == 50" style="padding-left: 30px;">
                <h3 style=" font-size: 30px; color: #2d3640; padding-top: 20px;"><span @click="orderSupportWizardStep = 1" class="backo">[geri]</span></h3>
                <p  class="text2" style="  font-size: 17px; color: rgb(118, 129, 145); font-weight: normal; margin-top: 30px; line-height: 1.55; ">
                  26 Mayıs 2021 saat 12:00'dan sonra ödemesi yapılan siparişlerin ödemesi alıcı tarafından onaylandıktan sonra <span style="font-weight: 400; color: #2D3640">Bionluk Bakiyem</span> hesabına aktarılıyor. Anasayfanda sol sütunda bulunan <router-link style="color: #00a575;" to="/panel/cuzdan">Bakiyem</router-link>'e tıklayarak tüm hesap hareketlerini takip edebilir ve hesabına paranı çekebilirsin.
                  <br><br>
                  Detaylı bilgi için; <router-link to="/destek/odemeler-24">https://bionluk.com/destek/odemeler-24</router-link>
                  <br><br>
                  Buna rağmen ödemende bir sorun yaşıyorsan bize <router-link style="color: #00a575; font-weight: 600" to="/tickets">Destek Merkezi</router-link> üzerinden ulaşabilirsin.
                  <br><br>
                </p>

              </div>
              <div v-else style="padding-left: 30px;">
                <h3 style=" font-size: 30px; color: #2d3640; padding-top: 20px;">{{selectedSupportSubCategoryInfo.header_text}} <span @click="orderSupportWizardStep = 1" class="backo">[değiştir]</span></h3>

                <template  v-if="selectedSupportSubCategoryInfo.extra_field === 'extend_delivery'">
                  <p class="text2" style=" margin-top: 30px; color: #2d3640">Süreyi kaç gün uzatmak istersin?</p>

                  <div class="hiddendiv"></div>
                  <div style="display: flex; align-items: center;">
                    <custom-select label="Ekstra Gün"
                                   id="extend-delivery-time"
                                   v-model="orderSupportExtraFieldValue"
                                   :options="orderSupportDaysArr"
                                   :customOption="{name: 'title', value:'id'}"
                                   style="width: 193px; "/>
                    <p style="margin-left: 20px; font-weight: 300;">Sipariş <span style="font-weight: 500">{{ order.deadline_atb + (orderSupportExtraFieldValue * 86400 * 1000)  | customDateFormat('dd MMMM, yyyy') }}</span> tarihinde teslim edilmeli</p>
                  </div>
                  <div class="hiddendiv"></div>
                </template>


                <template  v-if="selectedSupportSubCategoryInfo.extra_field === 'auto_partial_cancel'">
                  <p class="text2" style=" margin-top: 30px; color: #2d3640">Hangi oranda sipariş tutarının iade edilmesini istersin?</p>

                  <div class="hiddendiv"></div>
                  <div style="display: flex; align-items: center;">
                    <custom-select label="İade Oranı"
                                   id="partial-cancel-arr"
                                   v-model="orderSupportExtraFieldValue"
                                   :options="orderSupportPercentageArr"
                                   :customOption="{name: 'title', value:'id'}"
                                   style="width: 193px; "/>
                    <p v-if="orderSupportExtraFieldValue" style="margin-left: 20px; font-weight: 300;">Siparişin <span style="font-weight: 500">%{{orderSupportExtraFieldValue}} oranındaki tutarı ({{partialCancelValue }} TL)</span> alıcıya iade edilecek</p>
                  </div>
                  <div class="hiddendiv"></div>
                </template>


                <p v-if="selectedSupportSubCategoryInfo.header_textarea" class="text2" style=" margin-top: 30px; color: #2d3640">{{selectedSupportSubCategoryInfo.header_textarea}}</p>
                <p v-else-if="orderSupportTicketTitle" class="text2" style=" margin-top: 30px; color: #2d3640">{{orderSupportTicketTitle}}</p>
                <textarea maxlength="600" v-model="supportTicketMessage" class="c-text-area" style="padding:10px;width: 685px;height: 100px; margin-top:15px;" placeholder="Mesajını buraya yazabilirsin...">

              </textarea>

                <div style="display: flex; justify-content: space-between; margin-top: 15px; width: 705px; ">
                  <div style="text-align: left">


                    <div style="display: flex; align-items: center; height: 18px; margin-bottom: 20px;">
                      <img src="https://gcdn.bionluk.com/site/cicons/ic-attachment.svg" onload="SVGInject(this)" class="attach-icon">
                      <p @click="chooseFiles('order_support_upload')" class="start-upload-text">Dosya Ekle</p>
                      <input id="order_support_upload" style="visibility: hidden" type="file" @change="onFileChange($event, Constants.UPLOAD_TYPES.TICKET)">
                    </div>

                    <div v-for="file in supportFiles" style="margin-bottom: 15px;">
                      <div style="display: flex; align-items: center">
                        <div v-if="$store.state.percentCompleted.temp_icon_png[file.stamp]" @click="cancelUpload(file.stamp)">
                          <img  src="https://gcdn.bionluk.com/site/cicons/ic-trash.svg" onload="SVGInject(this)" class="delete-icon">
                        </div>
                        <div v-else @click="deleteFile(file.externalID, 'ticket')">
                          <img  src="https://gcdn.bionluk.com/site/cicons/ic-trash.svg" onload="SVGInject(this)" class="delete-icon">
                        </div>

                        <img v-if="file.icon_png" style="height: 24px; width: 19px; padding-left: 12px; " :src="file.icon_png">
                        <img v-else-if="$store.state.percentCompleted.temp_icon_png[file.stamp]" style="height: 24px; width: 19px; padding-left: 12px; " :src="$store.state.percentCompleted.temp_icon_png[file.stamp]">
                        <div style="display: flex; flex-direction: column; margin-left: 5px;">
                          <a target="_blank" :href="file.url" style="color: #2d3640; font-weight: 500; font-size: 13px;">{{ file.filename }}</a>
                          <progress v-if="$store.state.percentCompleted.ticket[file.stamp]" style="margin-top: 5px; margin-left: 0;" class="progressbar-purple" :value='$store.state.percentCompleted.ticket[file.stamp]' max="100"></progress>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div style="color: #8b95a1; font-size: 13px; font-weight: 500">
                    {{supportTicketMessage.length}} / <span style="color: #2d3640">600</span>
                  </div>
                </div>

                <p v-if="selectedSupportSubCategoryInfo.foot_note" style="margin-top:10px;color: #2d3640; font-weight: 600; font-size: 14px;"><span style="font-weight: 800">Önemli Not:</span> {{selectedSupportSubCategoryInfo.foot_note}}</p>
                <div style="display: flex; align-items: center; justify-content: center; margin-top: 40px; margin-bottom: 10px;">
                  <button @click="createOrderTicket" :disabled="computeDisabledForSupportTicket" class="cuper-green-button">Talebi Oluştur</button>
                </div>


              </div>

            </template>
          </div>

          <div class="ticket-message-area">
            <div class="ticket-message"
                 v-for="ticketMessage in ticketMessages"
                 :style="ticketMessage.ticket_user.username === user.username ? ' justify-content:flex-end; ' : 'justify-content:flex-end; flex-direction:row-reverse'">
              <div :class="ticketMessage.ticket_user.username === user.username ? 'bubble-left' : ticketMessage.ticket_user.username === 'Bionluk' ? 'bubble-bi' : 'bubble-right'">
                <div>
                  <span  v-html="ticketMessage.message" />
                  <template v-if="ticketMessage.asset">
                    <video v-if="ticketMessage.asset.type === 'video'" class="mt-2" @play="sendPlayEvent(ticketMessage.asset.id)" width="100%" :poster="ticketMessage.asset.info.thumbnailUrl" controls>
                      <source :src="ticketMessage.asset.info.videoUrl" type="video/mp4">
                      Tarayacınız video oynatımını desteklemiyor.
                    </video>
                    <a v-else :href="ticketMessage.asset.info.imageUrl" target="_blank" @click="sendPlayEvent(ticketMessage.asset.id)">
                      <img :src="ticketMessage.asset.info.imageUrl" width="100%" />
                    </a>
                  </template>
                  <span v-for="upload in ticketMessage.uploads" style="margin-top: 5px; margin-bottom: 8px; display: flex;">
                    <img style="height: 27px; width: 21px; " :src="upload.icon_png">
                    <a target="_blank" :href="upload.url" :download="upload.filename" style="color: #2d3640; padding-left: 8px;" >{{ upload.filename }}</a>
                  </span>
                </div>

                <p>
                  {{ticketMessage.ticket_user.username}}, {{ ticketMessage.created_at | customDateFormat('dd MMMM, yyyy - HH:mm') }}
                </p>

              </div>
              <div style="align-self: flex-end; margin-bottom: -6px;" :style="ticketMessage.ticket_user.username === user.username ? 'margin-left:5px;' : 'margin-right:5px; '">
                <img class="avatar" :src="ticketMessage.ticket_user.avatar_url">
              </div>
            </div>

            <div v-if="required_action.type" class="res-center-head-box" style="height:auto; margin-top:100px; padding-bottom:30px;">
              <div class="icon-area" style=" background-color: #5e6b79;">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-secure.svg" onload="SVGInject(this)" class="resolution-center-icon">
              </div>
              <div>
                <div class="text1">
                  {{required_action.header_text}}
                </div>
                <div class="text2">
                  {{required_action.text}}
                </div>

                <div v-if="required_action.remove_action" style="margin-top: 30px;  width: 340px; display: flex; justify-content: center;  margin-left:auto; margin-right:auto;">
                  <button @click="handleRequiredAction(required_action.type, 'remove')" :disabled="buttonLoading" class="cuper-white-button" style="width: 180px; height: 46px;">Talebi Geri Çek</button>
                </div>
                <div v-else-if="required_action.action === null" style="margin-top: 30px; width: 340px;  margin-left:auto; margin-right:auto; display: flex; justify-content: space-between">
                  <button @click="required_action.action = '-1'" :disabled="buttonLoading" class="cuper-white-button" style="width: 120px; height: 46px;">İptal</button>
                  <button @click="handleRequiredAction(required_action.type, 'approve')" :disabled="buttonLoading" class="cuper-green-button" style="width: 180px; height: 46px;">Onayla</button>
                </div>

                <div v-else-if="required_action.action === '-1'" style="margin-top: 20px; width: 600px">
                  <h3 style="text-align:left; font-size: 30px; color: #2d3640; padding-top: 20px;">Onaylamıyorum <span @click="required_action.action = null" class="backo" style="color: #2d3640">[değiştir]</span></h3>

                  <p  class="text2" style="text-align:left;margin-top: 30px; color: #2d3640">
                    {{required_action.cancel_text}}
                  </p>

                  <textarea maxlength="600" v-model="supportTicketMessage" class="c-text-area" style="padding:10px;width:100%;height: 100px; margin-top:15px;" placeholder="Mesajını buraya yazabilirsin...">

                </textarea>

                  <div style="display: flex; justify-content: space-between; margin-top: 15px;   ">
                    <div style="text-align: left">


                      <div style="display: flex; align-items: center; height: 18px; margin-bottom: 20px;">
                        <img src="https://gcdn.bionluk.com/site/cicons/ic-attachment.svg" onload="SVGInject(this)" class="attach-icon">
                        <p @click="chooseFiles('order_support_upload')" class="start-upload-text">Dosya Ekle</p>
                        <input id="order_support_upload" style="visibility: hidden" type="file" @change="onFileChange($event, Constants.UPLOAD_TYPES.TICKET)">
                      </div>

                      <div v-for="file in supportFiles" style="margin-bottom: 15px;">
                        <div style="display: flex; align-items: center">
                          <div v-if="$store.state.percentCompleted.temp_icon_png[file.stamp]" @click="cancelUpload(file.stamp)">
                            <img  src="https://gcdn.bionluk.com/site/cicons/ic-trash.svg" onload="SVGInject(this)" class="delete-icon">
                          </div>
                          <div v-else @click="deleteFile(file.externalID, 'ticket')">
                            <img  src="https://gcdn.bionluk.com/site/cicons/ic-trash.svg" onload="SVGInject(this)" class="delete-icon">
                          </div>

                          <img v-if="file.icon_png" style="height: 24px; width: 19px; padding-left: 12px; " :src="file.icon_png">
                          <img v-else-if="$store.state.percentCompleted.temp_icon_png[file.stamp]" style="height: 24px; width: 19px; padding-left: 12px; " :src="$store.state.percentCompleted.temp_icon_png[file.stamp]">
                          <div style="display: flex; flex-direction: column; margin-left: 5px;">
                            <a target="_blank" :href="file.url" style="color: #2d3640; font-weight: 500; font-size: 13px;">{{ file.filename }}</a>
                            <progress v-if="$store.state.percentCompleted.ticket[file.stamp]" style="margin-top: 5px; margin-left: 0;" class="progressbar-purple" :value='$store.state.percentCompleted.ticket[file.stamp]' max="100"></progress>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div style="color: #8b95a1; font-size: 13px; font-weight: 500">
                      {{supportTicketMessage.length}} / <span style="color: #2d3640">600</span>
                    </div>
                  </div>

                  <div style="display: flex; align-items: center; justify-content: center; margin-top: 40px; margin-bottom: 10px;">
                    <button @click="createOrderTicket" :disabled="buttonLoading || !supportTicketMessage.length" class="cuper-green-button">Tamamla</button>
                  </div>
                </div>



              </div>
            </div>

          </div>

          <template v-if="!required_action.type && ticketMessages.length > 0">
              <textarea maxlength="600" v-model="supportTicketMessage" class="c-text-area" style="width: 743px;height: 154px;" placeholder="Mesajını buraya yazabilirsin...">

              </textarea>
            <div style="display: flex; justify-content: space-between; width: 766px; margin: 15px auto;">
              <div style="text-align: left">


                <div style="display: flex; align-items: center; height: 18px; margin-bottom: 20px;">
                  <img src="https://gcdn.bionluk.com/site/cicons/ic-attachment.svg" onload="SVGInject(this)" class="attach-icon">
                  <p @click="chooseFiles('order_support_upload')" class="start-upload-text">Dosya Ekle</p>
                  <input id="order_support_upload" style="visibility: hidden" type="file" @change="onFileChange($event, Constants.UPLOAD_TYPES.TICKET)">
                </div>

                <div v-for="file in supportFiles" style="margin-bottom: 15px;">
                  <div style="display: flex; align-items: center">
                    <div v-if="$store.state.percentCompleted.temp_icon_png[file.stamp]" @click="cancelUpload(file.stamp)">
                      <img  src="https://gcdn.bionluk.com/site/cicons/ic-trash.svg" onload="SVGInject(this)" class="delete-icon">
                    </div>
                    <div v-else @click="deleteFile(file.externalID, 'ticket')">
                      <img  src="https://gcdn.bionluk.com/site/cicons/ic-trash.svg" onload="SVGInject(this)" class="delete-icon">
                    </div>

                    <img v-if="file.icon_png" style="height: 24px; width: 19px; padding-left: 12px; " :src="file.icon_png">
                    <img v-else-if="$store.state.percentCompleted.temp_icon_png[file.stamp]" style="height: 24px; width: 19px; padding-left: 12px; " :src="$store.state.percentCompleted.temp_icon_png[file.stamp]">
                    <div style="display: flex; flex-direction: column; margin-left: 5px;">
                      <a target="_blank" :href="file.url" style="color: #2d3640; font-weight: 500; font-size: 13px;">{{ file.filename }}</a>
                      <progress v-if="$store.state.percentCompleted.ticket[file.stamp]" style="margin-top: 5px; margin-left: 0;" class="progressbar-purple" :value='$store.state.percentCompleted.ticket[file.stamp]' max="100"></progress>
                    </div>
                  </div>
                </div>

              </div>

              <div style="color: #8b95a1; font-size: 13px; font-weight: 500">
                {{supportTicketMessage.length}} / <span style="color: #2d3640">600</span>
              </div>
            </div>
            <div style="display: flex; flex-direction: row-reverse;  width: 810px; margin-bottom: 50px;">
              <button @click="createOrderTicket" :disabled="buttonLoading || !supportTicketMessage.length" class="cuper-green-button">Mesaj Gönder</button>
            </div>

          </template>
        </div>
        <div v-show="tab === 'detail'" style="margin-top: 26px;  text-align: center; width: 100%; margin-bottom: 120px;">

          <div v-if="orderRequirements.show && orderRequirements.text" style="text-align: left">
            <p class="main-header">Freelancer'ın <span>İstediği Bilgiler</span></p>
            <div>

            </div>
            <div class="order-reqs">
              <div style="display: flex; padding: 30px;">
                <div style="width: 58px; margin-right:15px;align-self:flex-start;">
                  <img style="border: 1px solid #bfc8d2;border-radius: 50%; width: 58px; height: 58px;" :src="orderRequirements.avatar_url">
                </div>
                <div v-html="orderRequirements.text" style="word-break: break-word; align-self: flex-start; color: #5e6b79; line-height: 1.5; font-weight: 300; font-size: 16px;"></div>
              </div>
            </div>
          </div>

          <div v-if="showRealJobBox && !hideShowRealJobBox" style="margin-bottom: 50px;">
            <p v-if="order.kim.kimTitle == 'Alıcı'" class="main-header" style="text-align: left">Yaptığın İşi <span>Portfolyona Ekle</span></p>
            <div v-else class="main-header" style="text-align: left; display: flex; justify-content: space-between"><p>Freelancer'a <span>İzin Ver</span></p>
              <span @click="removeShowRealJobBox" class="tumu">Bir Daha Gösterme</span>
            </div>
            <div>
              <div class="edit-real-job-box">
                <div>
                  <div class="icon-area">
                    <img src="https://gcdn.bionluk.com/site/cicons/ic-my-portfolio.svg" onload="SVGInject(this)" class="portfolio-icon">
                  </div>
                </div>
                <template  v-if="order.kim.kimTitle == 'Alıcı'">
                  <div v-if="!showRealJob" class="text">
                    Eğer alıcı izin verirse yaptığın işi Bionluk portfolyona ekleyebilirsin. Alıcı henüz bu işi portfolyona eklemen için izin vermedi. Teslim ettiğin işe yorum ve puan verdikten sonra, ayrıca çalışmayı portfolyona eklemene izin verirse, sana haber vereceğiz.
                  </div>
                  <div v-else class="text">
                    Eğer alıcı izin verirse yaptığın işi Bionluk portfolyona ekleyebilirsin. Alıcı bu işi portfolyona eklemene izin veriyor. Çalışmayı portfolyona eklemek için <span @click="handleActivityClick('add_portfolio')">[buraya tıkla]</span>
                  </div>
                </template>
                <template v-else>
                  <div class="text">
                    <p>Freelancer’ın senin için yaptığı bu işi Bionluk portfolyosuna eklemesine izin vermek istersen aşağıdaki düğmeyi kullanabilirsin.</p>
                    <div style="display: flex; align-items: center; margin-top: 15px;">
                      <custom-switch :disabled="buttonLoading" v-model="showRealJob"/>
                      <p style="margin-left: 10px; color:#969fac; font-size: 14px; font-weight: 300; padding-bottom: 1px;">Freelancer'ın bu işi portfolyosuna eklemesine izin veriyorum.</p>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div v-if="showCommentBox" class="comment-box-container">
            <p class="main-header">Yorum yap & <span>Puan ver</span></p>
            <div class="comment-box">
              <div class="comment-box-header">
                <p v-if="showCommentBox === 'selling'">Sipariş ve alıcı ile ilgili görüşlerini bildirmen ve puan vermen, <span>Bionluk Freelancer topluluğu için çok kıymetli.</span></p>
                <p v-else>Sipariş ve freelancer ile ilgili görüşlerini bildirmen ve puan vermen, <span>Bionluk Freelancer topluluğu için çok kıymetli.</span></p>
              </div>
              <div class="comment-box-body">
                <div  style="display: flex; padding: 40px 50px;">
                  <div style="flex:1">


                    <p style="color: #2d3640; font-size: 16px; font-weight: bold;">İletişim</p>
                    <p v-if="showCommentBox ==='buying'" style="max-width:200px;margin-top:13px; margin-bottom:18px;color: #5e6b79; font-size: 14px; font-weight: 300;">Freelancer’ın seninle kurduğu iletişim nasıldı?</p>
                    <p v-else style="max-width:200px;margin-top:13px; margin-bottom:18px;color: #5e6b79; font-size: 14px; font-weight: 300;">Alıcının seninle kurduğu iletişim nasıldı?</p>
                    <star-rating
                      v-model="communicationStar"
                      :max-rating="5"
                      :star-size="39"
                      :show-rating="false"
                      :increment="0.5"
                      inactive-color="#bfc8d2"
                      active-color="#ffbf00"></star-rating>

                    <div class="slider-container">
                      <div style="width: 210px; margin-left:-10px;position: relative">
                        <input :style="{'background': 'linear-gradient(90deg, #ffbf00 '+ ((communicationStar)) *20 +'%, #FFF 1%)'}" v-model.number="communicationStar" type="range" :step="0.5" :min="0" :max="5">
                      </div>
                      <p v-if="communicationStar" class="rating-text">{{communicationStar}}{{communicationStar === parseInt(communicationStar, 10) ? '.0':''}}</p>
                    </div>

                  </div>
                  <div v-if="showCommentBox === 'buying'" style="flex:1">
                    <p style="color: #2d3640; font-size: 16px; font-weight: bold;">Hizmet Kalitesi</p>
                    <p style="max-width:200px;margin-top:13px; margin-bottom:18px;color: #5e6b79; font-size: 14px; font-weight: 300;">Siparişin anlaştığınız şekilde teslim edildi mi?</p>
                    <star-rating
                      v-model="serviceAsDescribedStar"
                      :max-rating="5"
                      :star-size="39"
                      :show-rating="false"
                      :increment="0.5"
                      inactive-color="#bfc8d2"
                      active-color="#ffbf00"></star-rating>

                    <div class="slider-container">
                      <div style="width: 210px; margin-left:-10px;position: relative">
                        <input :style="{'background': 'linear-gradient(90deg, #ffbf00 '+ ((serviceAsDescribedStar)) *20 +'%, #FFF 1%)'}" v-model.number="serviceAsDescribedStar" type="range" :step="0.5" :min="0" :max="5">
                      </div>
                      <p v-if="serviceAsDescribedStar" class="rating-text">{{serviceAsDescribedStar}}{{serviceAsDescribedStar === parseInt(serviceAsDescribedStar, 10) ? '.0':''}}</p>
                    </div>

                  </div>
                  <div v-else style="flex:1">
                    <p style="color: #2d3640; font-size: 16px; font-weight: bold;">İş Özeti</p>
                    <p style="max-width:200px;margin-top:13px; margin-bottom:18px;color: #5e6b79; font-size: 14px; font-weight: 300;">Talepleri anlaşılır ve yeterince açık mıydı?</p>
                    <star-rating
                      v-model="briefQualityStar"
                      :max-rating="5"
                      :star-size="39"
                      :show-rating="false"
                      :increment="0.5"
                      inactive-color="#bfc8d2"
                      active-color="#ffbf00"></star-rating>

                    <div class="slider-container">
                      <div style="width: 210px; margin-left:-10px;position: relative">
                        <input :style="{'background': 'linear-gradient(90deg, #ffbf00 '+ ((briefQualityStar)) *20 +'%, #FFF 1%)'}" v-model.number="briefQualityStar" type="range" :step="0.5" :min="0" :max="5">
                      </div>
                      <p v-if="briefQualityStar" class="rating-text">{{briefQualityStar}}{{briefQualityStar === parseInt(briefQualityStar, 10) ? '.0':''}}</p>
                    </div>
                  </div>
                  <div style="flex:1">
                    <p style="color: #2d3640; font-size: 16px; font-weight: bold;">Zamanlama</p>
                    <p v-if="showCommentBox ==='buying'" style="max-width:200px;margin-top:13px; margin-bottom:18px;color: #5e6b79; font-size: 14px; font-weight: 300;">Siparişini zamanında teslim aldın mı?</p>
                    <p v-else style="max-width:200px;margin-top:13px; margin-bottom:18px;color: #5e6b79; font-size: 14px; font-weight: 300;">Sipariş zamanında onaylandı mı?</p>
                    <template v-if="showCommentBox ==='buying'">
                      <star-rating
                        v-model="deliveryTimeStar"
                        :max-rating="5"
                        :star-size="39"
                        :show-rating="false"
                        :increment="0.5"
                        inactive-color="#bfc8d2"
                        active-color="#ffbf00"></star-rating>
                      <div class="slider-container">
                        <div style="width: 210px; margin-left:-10px;position: relative">
                          <input :style="{'background': 'linear-gradient(90deg, #ffbf00 '+ ((deliveryTimeStar)) *20 +'%, #FFF 1%)'}" v-model.number="deliveryTimeStar" type="range" :step="0.5" :min="0" :max="5">
                        </div>
                        <p v-if="deliveryTimeStar" class="rating-text">{{deliveryTimeStar}}{{deliveryTimeStar === parseInt(deliveryTimeStar, 10) ? '.0':''}}</p>
                      </div>
                    </template>
                    <template v-else>
                      <star-rating
                        v-model="approvalTimeStar"
                        :max-rating="5"
                        :star-size="39"
                        :show-rating="false"
                        :increment="0.5"
                        inactive-color="#bfc8d2"
                        active-color="#ffbf00"></star-rating>
                      <div class="slider-container">
                        <div style="width: 210px; margin-left:-10px;position: relative">
                          <input :style="{'background': 'linear-gradient(90deg, #ffbf00 '+ ((approvalTimeStar)) *20 +'%, #FFF 1%)'}" v-model.number="approvalTimeStar" type="range" :step="0.5" :min="0" :max="5">
                        </div>
                        <p v-if="approvalTimeStar" class="rating-text">{{approvalTimeStar}}{{approvalTimeStar === parseInt(approvalTimeStar, 10) ? '.0':''}}</p>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div class="comment-box-footer">
                <div style="position: relative">

                       <textarea id="comment-input" maxlength="300" v-model="commentMessage" class="c-text-area" style="width: 743px;height: 105px;"
                                 :placeholder="commentTextAreaPlaceholder"></textarea>
                  <div :class="{'emoji-img': true, 'emoji-img-active': openEmojiPicker}" @click="openEmojiPicker = !openEmojiPicker"></div>
                  <emoji-picker class="emoji-picker"
                                v-click-outside="pageClickHandler"
                                v-if="openEmojiPicker"
                                :emojiSize="32"
                                :emojisByRow="7"
                                :showSearch="true"
                                :i18n="{ search: 'Ara...'}"
                                @select="addEmoji"/>
                </div>
                <div style="color: #8b95a1; font-size: 13px; font-weight: 500; text-align: right; margin-right: 50px; margin-top:10px">
                  {{commentMessage.length}} / <span style="color: #2d3640">300</span>
                </div>

                <div v-if="showCommentBox === 'buying'" style="display: flex; align-items: center;">
                  <custom-switch v-model="showRealJob"/>
                  <p style="margin-left: 10px; color:#2d3640; font-size: 14px; font-weight: 300; padding-bottom: 3px;">Freelancer'ın bu işi portfolyosuna eklemesine izin veriyorum.</p>
                </div>

                <div v-if="showCommentBox !=='buying'" class="hiddendiv20"></div>
                <div style="display: flex; flex-direction: row-reverse; margin-right: 50px; width:793px; margin-top: 20px; ">
                  <button @click="rate" :disabled="buttonLoading || !commentMessage.length" class="cuper-green-button" style="font-size:14px;">Değerlendirmeyi Gönder</button>
                </div>

              </div>


            </div>


          </div>
          <div v-if="showSummary" style="margin-bottom: 50px; text-align: left">
            <p class="main-header">Sipariş <span>Özeti</span></p>

            <OrderBox from="order" :list="summaryBox"></OrderBox>

            <template v-if="summary.files.length">
              <p class="main-header" style="margin-top: 50px;">Siparişe Ait <span>Dosyalar</span></p>
              <div class="order-files-containter">
                <div class="order-file-flex" v-for="file in summary.files">
                  <div class="order-file">
                    <div style="display: flex; padding-left: 20px;">
                      <a target="_blank" :href="file.url" :download="file.filename">
                        <img class="icon_png" :src="file.icon_png">
                      </a>
                      <div style=" padding-left: 10px;">
                        <p class="filename"> <a target="_blank" :href="file.url" :download="file.filename">{{file.filename}}</a></p>
                        <p class="filedate" style="margin-top: 2px;">{{ file.filedate | customDateFormat('dd MMMM, yyyy - HH:mm') }}</p>
                      </div>
                    </div>
                    <a target="_blank" :href="file.url" :download="file.filename" class="download-icon-container">
                      <img src="https://gcdn.bionluk.com/site/cicons/ic-download.svg" onload="SVGInject(this)" class="download-icon">
                    </a>

                  </div>
                </div>
              </div>
            </template>

            <div v-if="summary.comments.length" style="margin-top: 35px;">
              <div class="summary-comments-container">
                <div v-for="comment in summary.comments">
                  <p class="main-header" v-html="comment.header"></p>
                  <div class="summary-comment">
                    <div style="padding: 20px 10px 20px 20px; display: flex;">
                      <div>
                        <img :src="comment.avatar_url" style="width: 38px; height: 38px; border-radius: 50%; border: 1px solid #eff3f8">
                      </div>
                      <div style="margin-left: 10px;">
                        <div style="display: flex; ">
                          <p class="username" >{{comment.username}}</p>
                          <img src="https://gcdn.bionluk.com/site/cicons/ic-star.svg" onload="SVGInject(this)" class="star-icon">
                          <p class="rating">{{comment.rating}}</p>
                          <div @click="showCommentDetail(comment.comment_id)" class="star-detail-info-icon-wrapper"  style=" margin-top: 3.5px; margin-left: 3px; display: flex; ">
                            <img style="width: 12px; height: 12px;" src="https://gcdn.bionluk.com/site/cicons/ic-info.svg" onload="SVGInject(this)" class="star-detail-info-icon"/>
                          </div>
                        </div>
                        <div @focusout="hideCommentDetail" tabindex="99" :id="'rating-star-'+comment.comment_id" class="rating-detail" style=" background: white; padding: 10px 20px 0px 20px; position:absolute; visibility:hidden; outline: none!important;">
                          <div v-for="comment_detail_item in comment.comment_detail" style="margin-bottom: 15px;">
                            <p class="rating-star-detail-title" >{{comment_detail_item.title}}</p>
                            <div style="display: flex; margin-top: 5px;">
                              <img src="https://gcdn.bionluk.com/site/cicons/ic-star.svg" onload="SVGInject(this)" class="star-icon">
                              <p class="rating">{{comment_detail_item.grade}}</p>
                            </div>

                          </div>
                        </div>

                        <div class="comment" v-html="comment.comment"></div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div class="order-activities">
            <div style="display: flex; justify-content: space-between">
              <p class="main-header">Sipariş <span>Süreci</span></p>
              <p v-if="!buttonLoading" @click="sendMessage" class="tumu">{{order.kim.kimTitle === "Freelancer" ? "Freelancer'a" : "Alıcıya"}} Mesaj Gönder</p>
            </div>

            <div class="white-content">
              <div v-for="(activity, ii) in orderActivities" class="activity-for" :key="ii">
                <div :style="ii === 0 || orderActivities[ii-1].created_day !== activity.created_day ? 'opacity:1; margin-bottom:60px;' : 'opacity:0; margin-bottom:20px; '" class="line-text"><span>{{activity.created_day}}</span></div>
                <div  class="activity" :style="activity.activity_style.box_info.style">
                  <div class="icon-rounded" :style="activity.activity_style.icon_info.style">
                    <img :src="activity.activity_style.icon_info.icon" onload="SVGInject(this)" class="icon-svg">
                  </div>

                  <p class="header-text" v-html="activity.header"></p>
                  <p class="text" v-html="activity.text"></p>
                  <p v-if="activity.sub_header" class="sub-header-text" v-html="activity.sub_header"></p>

                  <div v-if="activity.message.show" class="activity-message">
                    <div class="avatar-container">
                      <img class="avatar" :src="activity.message.avatar_url">
                    </div>
                    <div v-html="activity.message.text" class="message"></div>
                  </div>

                  <div v-for="(upload, iii) in activity.uploads" class="activity-uploads" :style="iii !== 0 ? ' border-top: 1px solid rgba(38, 202, 154, 0.2);' : ''">
                    <div style="display: flex">
                      <a target="_blank" :href="upload.url" :download="upload.filename">
                        <img class="icon-png" :src="upload.icon_png">
                      </a>
                      <a target="_blank" :href="upload.url" :download="upload.filename" class="filename">
                        {{upload.filename}}
                      </a>
                    </div>

                    <a target="_blank" :href="upload.url" :download="upload.filename" class="download-icon-container">
                      <img src="https://gcdn.bionluk.com/site/cicons/ic-download.svg" onload="SVGInject(this)" class="download-icon">
                    </a>
                  </div>
                  <div v-if="activity.button.show" style="margin-top: 15px;">
                    <button :disabled="buttonLoading" @click="handleActivityClick(activity.button.action)" style="width: 220px; height: 44px" class="cuper-white-button">{{activity.button.text}}</button>
                  </div>
                  <div v-if="activity.text_button.show" style="margin-top: 15px;">
                    <span @click="handleActivityClick(activity.text_button.action)" class="text-button" :style="buttonLoading ? 'opacity:0.5; cursor:default' : ''">{{activity.text_button.text}}</span>
                  </div>
                </div>
                <p class="time">{{ activity.created_at | customDateFormat('HH:mm') }}</p>
              </div>
            </div>
          </div>
          <OrderBox v-if="!showSummary" from="order" :list="summaryBox"></OrderBox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
	import OrderBox from "../../../../components/boxSliders/order";
  import { VEmojiPicker } from "v-emoji-picker-bi";
	import CustomSwitch from '../../../../components/CustomSwitch'
  import CustomSelect from "../../../../components/CustomSelect";

  export default {
    name: "src-pages-body-workstation-orders-detail-v3",
    components: {
      OrderBox,
      emojiPicker: VEmojiPicker,
      CustomSwitch,
      CustomSelect
    },
    data() {
      return {

        sessionDuration:0,
        reRender:true,
      	tab:'detail',
	      pageLoaded: false,
        buttonLoading: false,
        ticketMessages:[],
        order:{},
	      summaryBox:[],
	      orderActivities:[],
	      orderRequirements:{show:false},
        showCommentBox: false,
	      showSummary: false,
        showRealJobBox: false,
	      hideShowRealJobBox: false,
        summary:{
      		comments:[],
          files:[]
        },

        //v-model
        supportTicketMessage:'',
	      supportFiles:[],
	      commentMessage:'',
	      showRealJob: true,
	      communicationStar:0,
	      serviceAsDescribedStar:0,
	      deliveryTimeStar:0,
	      approvalTimeStar:0,
        briefQualityStar:0,


	      openEmojiPicker: false,

        required_action:{
          id:null,
          type:null,
          action:null,
          remove_action:false,
        },

        orderSupportCategories:[],
        selectedSupportCategoryId:null,
        selectedSupportSubCategoryId:null,
        orderSupportTicketTitle:'',
        orderSupportWizardStep:1,
        orderSupportDaysArr:[],
        orderSupportPercentageArr:[],
        orderSupportExtraFieldValue:'',

        orderSupportCancelContinueText: '',
        selectedCommentId: null,
      }
    },
    methods: {
      sendPlayEvent(id) {
        this.Helper.trackEvents.customEvent("custom_video_view", {
          title: null,
          id,
          page: this.$store.state.componentMap.page,
          impression_id:this.order.id
        });
      },
      updateStars(){
        let x = this.getSpansWithClass('vue-star-rating-star');
        let pointValue = "14.772500 0.500000, 10.310000 10.312500, 0.528750 11.203750, 0.316415 11.367075, 0.373750 11.628750, 7.782500 19.100000, 5.735000 29.441250, 5.836816 29.694906, 6.110000 29.703750, 14.967500 24.303750, 23.820000 29.706250, 24.092447 29.697558, 24.195000 29.445000, 22.207500 19.093750, 29.625000 11.628750, 29.682662 11.367579, 29.471250 11.203750, 19.685000 10.312500, 15.227500 0.500000, 15.000000 0.353652, 14.772500 0.500000"
        for (let i = 0; i < x.length; i++) {
          let ele = x[i].childNodes;
          ele[4].setAttribute('points', pointValue)
          ele[6].setAttribute('points', pointValue)
          ele[8].setAttribute('points', pointValue)
        }
      },
      hideCommentDetail(){
        if(this.selectedCommentId){
          const ele2 = document.getElementById('rating-star-'+this.selectedCommentId);
          if(ele2){
            ele2.style.visibility = 'hidden'
          }
          this.selectedCommentId = null;
        }

      },
      showCommentDetail(commentId){

        if(this.selectedCommentId && this.selectedCommentId === commentId){
          this.hideCommentDetail()
        } else {
          const ele = document.getElementById('rating-star-'+commentId);
          if(ele){
            if(this.selectedCommentId){
              const ele2 = document.getElementById('rating-star-'+this.selectedCommentId);
              if(ele2){
                ele2.style.visibility = 'hidden'
              }
            }
            ele.style.visibility = 'visible'
            this.$nextTick(() => this.updateStars())
            ele.focus()
            this.selectedCommentId = commentId;
          }
        }

      },
	    removeShowRealJobBox() {
		    this.buttonLoading = true;
		    this.showRealJobBox = false;
		    this.api.orders.hideShowRealJobBox(this.order.id, this.$Progress)
			    .then(({data}) => {
				    let result = data;
				    if (result.success) {
					    //this.$toasted.global.infoToast({description: result.message});
				    } else {
					    this.$toasted.global.errorToast({description: result.message});
				    }
				    this.buttonLoading = false;
			    })
			    .catch(err => {
				    this.buttonLoading = false;
				    this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
			    });
      },
	    rate() {
		    if (this.showCommentBox === 'selling') {
		    	this.rateBuyer()
        } else if(this.showCommentBox === 'buying'){
			    this.rateSeller()
		    }
	    },
	    arrangeRateSellerBody() {
		    let wouldRecommend = this.deliveryTimeStar + this.serviceAsDescribedStar + this.communicationStar > 8 ? 1 : -1;
		    let showRealJob = this.showRealJob ? 1 : 0;

		    return {
			    order_id: this.order.id,
			    would_recommend: wouldRecommend,
			    service_as_described: this.serviceAsDescribedStar,
			    delivery_time: this.deliveryTimeStar,
			    communication: this.communicationStar,
			    comment: this.commentMessage,
			    special_note: null,
			    show_real_job: showRealJob,
		    }
	    },
	    rateSeller() {
		    this.buttonLoading = true;
		    this.api.orders.rateSeller(this.arrangeRateSellerBody(), this.$Progress)
			    .then(({data}) => {
				    let result = data;
				    if (result.success) {
					    this.getOrder(this.$store.state.routerParams[0]);
					    window.scrollTo(0, 0);
				    } else {
					    this.$toasted.global.errorToast({description: result.message});
				    }
				    this.buttonLoading = false;
			    })
			    .catch(err => {
				    this.buttonLoading = false;
				    this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
			    });
	    },
	    arrangeRateBuyerBody() {
		    let wouldRecommend = this.approvalTimeStar + this.briefQualityStar + this.communicationStar > 8 ? 1 : -1;
		    let showRealJob = this.showRealJob ? 1 : 0;

		    return {
			    order_id: this.order.id,
			    would_recommend: wouldRecommend,
			    brief_quality: this.briefQualityStar,
			    approval_time: this.approvalTimeStar,
			    communication: this.communicationStar,
			    comment: this.commentMessage,
			    special_note: null,
			    show_real_job: showRealJob,
		    }

	    },
	    rateBuyer() {
		    this.buttonLoading = true;
		    this.api.orders.rateBuyer(this.arrangeRateBuyerBody(), this.$Progress)
			    .then(({data}) => {
				    let result = data;
				    if (result.success) {
					    this.getOrder(this.$store.state.routerParams[0]);
					    window.scrollTo(0, 0);
				    } else {
					    this.$toasted.global.errorToast({description: result.message});
				    }
				    this.buttonLoading = false;
			    })
			    .catch(err => {
				    this.buttonLoading = false;
				    this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
			    });
	    },
	    addEmoji(emoji) {
		    this.commentMessage = this.commentMessage + " " + emoji.data;
		    const ele = document.getElementById('comment-input');
		    ele.focus();
	    },
	    pageClickHandler() {
		    this.openEmojiPicker = false;
	    },
	    startOrderForBuyer() {
		    this.buttonLoading = true;
		    this.api.orders.startOrderForBuyer(this.order.id, this.$Progress)
			    .then(({data}) => {
				    let result = data;
				    if (result.success) {
				    } else {
					    this.$toasted.global.errorToast({description: result.message});
				    }
				    this.buttonLoading = false;
			    })
			    .catch(err => {
				    this.buttonLoading = false;
				    this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
			    });
	    },
	    sendMessage() {
		    this.buttonLoading = true;
		    if (!this.$store.state.user.uuid) {
			    this.$router.push("/login");
		    } else {
			    this.buttonLoading = true;
			    this.api.workstation.chatOpen(this.order.kim.username, 0,1)
				    .then(({data}) => {
					    let result = data;
					    if (result.success) {
						    let conversationSendData = {
							    r_username: this.order.kim.username,
							    r_avatar_url: this.order.kim.avatar_url,
							    r_id: result.data.receiver.id,
							    r_uuid: result.data.receiver.uuid,
							    fromWhere: 'orderdetail',
							    c_uuid: result.data.c_uuid,
						    };
						    console.log('csend',conversationSendData);
						    this.EventBus.$emit('externalOpenChat', conversationSendData);
					    } else {
						    this.$toasted.global.errorToast({description: result.message});
					    }
					    this.buttonLoading = false;
				    })
				    .catch(err => {
					    this.buttonLoading = false;
					    this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
				    });
		    }
	    },
      handleRequiredAction(action, type){
        if(action !== 'auto_approve_requested') {
          if (confirm('Bu işlem geri alınması mümkün olmayabilir. Bu işlemi onaylıyor musun?') === true) {
            this.buttonLoading = true
            this.api.orders.orderCronAction(this.required_action.id, this.order.id, type, this.$Progress)
              .then(({data}) => {
                let result = data
                if (result.success) {
                  this.required_action = {
                    id: null,
                    type: null,
                    action: null,
                    remove_action: false,
                  }
                  this.ticketMessages = result.data.all_messages
                  this.$toasted.global.infoToast({description: result.message})
                } else {
                  this.$toasted.global.errorToast({description: result.message})
                }
                this.buttonLoading = false;
              })
              .catch(() => {
                this.buttonLoading = false;
                this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
              })
          }
        } else {
          this.handleActivityClick('approve')
        }

      },
	    handleActivityClick(buttonAction){
    		if (buttonAction === 'resolution_center') {
    			this.tab = 'support'
    			window.scrollTo(0,0)
        }
		    if (['add_portfolio', 'start_order', 'deliver', 'approve', 'revision'].includes(buttonAction)) {
			    this.buttonLoading = true;

			    let modalInfo = {
			      total: (this.order.total - this.order.total_refund),
				    orderID: this.order.id,
				    files:this.summary.files,
            summaryBox:this.summaryBox[0],
				    buttonAction
			    };
			    this.$store.commit(this.types.ACTIVE_MODAL, {  modalType: this.Modals.ORDER_ACTION, info:modalInfo});
			    this.buttonLoading = false;
		    }
		    if (buttonAction === 'start_order_for_buyer') {
		    	this.startOrderForBuyer()
		    }
		    if (buttonAction === 'send_message') {
			    this.sendMessage()
		    }
		    if (buttonAction === 'scroll_rate') {
		    	window.scrollTo(0,0)
        }
      },
	    createOrderTicket() {
	    	this.buttonLoading = true;
		    let IDs = [];
		    this.supportFiles.forEach(function (file) {
			    IDs.push(file.externalID);
		    });

		    let jsonObj = {
		      support_category_id: this.selectedSupportCategoryId,
          support_sub_category_id: this.selectedSupportSubCategoryId,
          support_category_title: this.orderSupportTicketTitle,
          support_category_extra_field: this.orderSupportExtraFieldValue,
          support_category_partial_field: this.partialCancelValue,
          required_action:this.required_action
        };


		    this.api.general.createOrderTicket(this.supportTicketMessage, this.order.id, IDs, JSON.stringify(jsonObj), this.$Progress)
			    .then(({data}) => {
				    let result = data;
				    if (result.success) {
					    this.ticketMessages = result.data.all_messages;
					    this.supportTicketMessage = '';
					    this.supportFiles = [];


              this.selectedSupportCategoryId = null;
              this.selectedSupportSubCategoryId = null;
              this.orderSupportTicketTitle = '';
              this.orderSupportExtraFieldValue = '';

					    this.$toasted.global.infoToast({description: result.message});

				    } else {
					    this.$toasted.global.errorToast({description: result.message});
				    }
				    this.buttonLoading = false;
			    })
			    .catch(err => {
				    this.buttonLoading = false;
				    this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
			    })
	    },
	    chooseFiles(id) {
		    document.getElementById(id).click()
	    },
      cancelUpload(timeStamp){
	      this.cancelUploadRequest(parseInt(timeStamp));

	      let supportFileIndex = this.supportFiles.findIndex(f => f.stamp === timeStamp);
	      if(supportFileIndex !== -1){
		      this.supportFiles.splice(supportFileIndex, 1);
        }
      },
	    deleteFile(uploadID, type) {
		    this.api.general.updateUploadStatus(uploadID, this.Constants.UPLOAD_STATUSES.DELETE)
			    .then(({data}) => {
				    let result = data;

				    if (result.success) {
              if (type === this.Constants.UPLOAD_TYPES.TICKET) {
                this.supportFiles = this.supportFiles.filter(function (file) {
                  return file.externalID !== uploadID
                });
              } else {
                this.files = this.files.filter(function (file) {
                  return file.externalID !== uploadID
                });
              }

					    this.$toasted.global.infoToast({description: result.message});
				    } else {
					    this.$toasted.global.errorToast({description: result.message});
				    }
			    })
			    .catch(err => {
			    	console.log(err);
				    this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
			    });
	    },
	    onFileChange(e, type) {
		    let files = e.target.files || e.dataTransfer.files;
		    if (!files.length)
			    return;
		    this.buttonLoading = true;

		    let timeStamp = +new Date();


        let rand = Math.floor(Math.random() * 1000) + 1;
        timeStamp = timeStamp + rand;
        this.supportFiles.push({
	        url: null,
	        externalID: timeStamp,
	        filename: files[0].name,
	        stamp: timeStamp
        });

        this.uploadFileToGoogle(files[0], type, null, timeStamp)
          .then(result => {
          	let supportFileIndex = this.supportFiles.findIndex(f => f.stamp === timeStamp);
          	let supportFile = this.supportFiles[supportFileIndex];
	          supportFile.externalID = result.externalID;
	          supportFile.url = this.Constants.CDN_UPLOADS_G + result.uploadName,
		        supportFile.icon_png = result.icon_png;
	          this.supportFiles[supportFileIndex] = supportFile;


	          this.buttonLoading = false;

          }).catch(err => {
	        this.buttonLoading = false;

        });


		    e.target.value = ''

	    },
      getSpansWithClass(cssClass) {
        const elements = document.getElementsByTagName('svg')
        const out = []
        for (let i = 0; i < elements.length; i++) {
          if ((elements[i].className.baseVal) === cssClass) {
            out.push(elements[i])
          }
        }
        return out
	    },
	    getOrder(ID) {
        this.orderSupportCategories = [];
        this.selectedSupportCategoryId = null;
        this.selectedSupportSubCategoryId = null;
        this.orderSupportTicketTitle = '';
        this.orderSupportWizardStep = 1;
        this.orderSupportExtraFieldValue = '';

		    this.api.orders.getOrderv2(ID)
			    .then(({data}) => {
				    let result = data;
				    if (result.success) {
					    this.pageLoaded = true;
					    this.required_action = result.data.required_action;
					    this.ticketMessages = result.data.ticket_messages;
					    this.order = result.data.order
					    this.summaryBox = JSON.parse(JSON.stringify([]));
              this.summaryBox[0] = JSON.parse(JSON.stringify(result.data.summary_box));
					    this.orderActivities = result.data.order_activities;
					    this.orderRequirements = result.data.order_requirements;
					    this.showCommentBox = result.data.show_comment_box;
					    this.showSummary = result.data.show_summary;
              this.showRealJobBox = JSON.parse(JSON.stringify(false));
					    this.showRealJobBox = JSON.parse(JSON.stringify(result.data.show_real_job_box));
              this.showRealJob = JSON.parse(JSON.stringify(false));
					    this.showRealJob = JSON.parse(JSON.stringify(result.data.show_real_job));
              this.hideShowRealJobBox =  JSON.parse(JSON.stringify(false));
					    this.hideShowRealJobBox = result.data.hide_show_real_job_box;
					    this.summary = JSON.parse(JSON.stringify({
                comments:[],
                  files:[]
              }))
					    this.summary = JSON.parse(JSON.stringify(result.data.summary));
              this.orderSupportCategories = result.data.order_support_categories;
              if (result.data.forceContactModal) {
                this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.ORDER_CONTACT_INFO});
              }

              this.Helper.trackEvents.pageView(this.Helper.orderDetailPageProps(result.data));

              this.$store.state.indexHTMLTitle = 'Sipariş No: ' + this.order.id + '  | Bionluk';

              this.reRender = false;
              this.reRender = true;

				    } else {
					    this.$router.push(result.data.redirect_url);
					    this.$toasted.global.errorToast({description: result.message});
				    }


			    })
			    .catch(err => {
			    	console.log(err);
				    this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
			    });
	    }
    },
	  beforeUpdate() {
	    let x = this.getSpansWithClass('vue-star-rating-star');
	    for (let i = 0; i < x.length; i++) {
	    	let ele = x[i].childNodes;
		    ele[4].setAttribute('points',"14.772500 0.500000, 10.310000 10.312500, 0.528750 11.203750, 0.316415 11.367075, 0.373750 11.628750, 7.782500 19.100000, 5.735000 29.441250, 5.836816 29.694906, 6.110000 29.703750, 14.967500 24.303750, 23.820000 29.706250, 24.092447 29.697558, 24.195000 29.445000, 22.207500 19.093750, 29.625000 11.628750, 29.682662 11.367579, 29.471250 11.203750, 19.685000 10.312500, 15.227500 0.500000, 15.000000 0.353652, 14.772500 0.500000")
		    ele[6].setAttribute('points',"14.772500 0.500000, 10.310000 10.312500, 0.528750 11.203750, 0.316415 11.367075, 0.373750 11.628750, 7.782500 19.100000, 5.735000 29.441250, 5.836816 29.694906, 6.110000 29.703750, 14.967500 24.303750, 23.820000 29.706250, 24.092447 29.697558, 24.195000 29.445000, 22.207500 19.093750, 29.625000 11.628750, 29.682662 11.367579, 29.471250 11.203750, 19.685000 10.312500, 15.227500 0.500000, 15.000000 0.353652, 14.772500 0.500000")
		    ele[8].setAttribute('points',"14.772500 0.500000, 10.310000 10.312500, 0.528750 11.203750, 0.316415 11.367075, 0.373750 11.628750, 7.782500 19.100000, 5.735000 29.441250, 5.836816 29.694906, 6.110000 29.703750, 14.967500 24.303750, 23.820000 29.706250, 24.092447 29.697558, 24.195000 29.445000, 22.207500 19.093750, 29.625000 11.628750, 29.682662 11.367579, 29.471250 11.203750, 19.685000 10.312500, 15.227500 0.500000, 15.000000 0.353652, 14.772500 0.500000")
	    }
    },
    watch: {
      orderSupportWizardStep(newVal) {
        if (newVal === 2) {
          this.orderSupportExtraFieldValue = ''
          if (this.selectedSupportSubCategoryId === '40') {
            this.supportTicketMessage = 'Onay süresi geçtiği için siparişin onaylanmasını talep ediyorum. Teşekkürler.';
          } else {
            this.supportTicketMessage = ''
          }
          let ele = document.getElementById('select-support-category');
          if (ele) {
            ele.scrollIntoView({
              behavior: 'smooth'
            })
          }
        }
      },
      selectedSupportSubCategoryId(newVal) {
        if (newVal) {
          this.orderSupportTicketTitle = ''
        }
      },
      selectedSupportCategoryId(newVal) {
        if (newVal) {
          if (newVal === '5') {
            if (!this.order.completed_at) {
              this.orderSupportCancelContinueText = 'Teslim edilmeyen bir sipariş için onay talebinde bulunamazsın. Siparişin öncelikle freelancer tarafından teslim edilmiş olması gerekiyor'
            } else if (!this.order.approve_time_passed) {
              this.orderSupportCancelContinueText = 'Sipariş tesliminden sonra alıcının 72 saat onay süresi bulunmaktadır. Bu süre geçtikten sonra onay talebinde bulunabilirsin.'
            } else {
              this.orderSupportCancelContinueText = ''
            }
          } else {
            this.orderSupportCancelContinueText = ''
          }
          this.selectedSupportSubCategoryId = null
          this.orderSupportTicketTitle = ''
        }
      },
	    showRealJob(newVal) {
        if(this.showRealJobBox && this.pageLoaded && this.order.id && this.order.status === '4' && this.order.kim && this.order.kim.kimTitle && this.order.kim.kimTitle === 'Freelancer'){
	        let showRealJob = newVal ? 1 : 0;
          this.buttonLoading = true;

	        this.api.orders.giveShowRealJobPermission(this.order.id,showRealJob, this.$Progress)
		        .then(({data}) => {
			        let result = data;
			        if (result.success) {
				        //this.$toasted.global.infoToast({description: result.message});
			        } else {
				        this.$toasted.global.errorToast({description: result.message});
			        }
			        this.buttonLoading = false;
		        })
		        .catch(err => {
			        this.buttonLoading = false;
			        this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
		        });
        }
	    },
	    "$store.state.temp_icon_png": function (newValue, oldValue) {
		    this.supportFiles.forEach(function (file) {
			    if(newValue[file.stamp]){
				    file.icon_png = newValue[file.stamp];
          }
		    });
	    },

      "$store.state.routerParams": function (newValue, oldValue) {
        let path = this.$store.state.route.fullPath.split("/")[1];
        if (newValue && newValue.length && path === "orders") {
          this.pageLoaded = false;
          location.reload();
          //this.getOrder(newValue[0]);
        }
      },
      'route.query.tab': function (newVal, oldVal) {
        if (newVal === 'support') {
	        this.tab = 'support';
        } else if (newVal === 'detail') {
	        this.tab = 'detail';
        }
      },
    },
    computed: {
      partialCancelValue() {
        if (this.orderSupportExtraFieldValue && this.order && this.order.total) {
          return parseInt(this.orderSupportExtraFieldValue * this.order.total / 100)
        } else {
          return null;
        }
      },
      selectedSupportSubCategoryInfo() {
        if (!this.selectedSupportSubCategoryId) {
          return {}
        } else {
          return this.orderSupportCategories.find(({ id }) => id === this.selectedSupportSubCategoryId) || {}
        }
      },
      computeDisabledForSupportTicket(){
        if (this.buttonLoading){
          return true;
        } else if(this.orderSupportWizardStep === 1){
          if(!this.selectedSupportSubCategoryId){
            return true;
          } else {
            let selectedSupportSubCategory = this.orderSupportCategories.find(({ id }) => id === this.selectedSupportSubCategoryId)
            if(selectedSupportSubCategory && selectedSupportSubCategory.title && selectedSupportSubCategory.title === 'Diğer'){
              return !this.orderSupportTicketTitle;
            } else {
              return false;
            }

          }
        } else {
          // 2
          if(this.supportTicketMessage.length === 0) {
            return true
          } else {
            if(this.selectedSupportSubCategoryInfo && this.selectedSupportSubCategoryInfo.extra_field === 'extend_delivery'){
              return !this.orderSupportExtraFieldValue || this.orderSupportExtraFieldValue === '';
            } else if(this.selectedSupportSubCategoryInfo && this.selectedSupportSubCategoryInfo.extra_field === 'auto_partial_cancel'){
              return !this.orderSupportExtraFieldValue || this.orderSupportExtraFieldValue === '';
            } else {
              return false
            }
          }
        }
      },
      orderSupportMainCategories(){
        return this.orderSupportCategories.filter(category => category.parent_id === null)
      },
      orderSupportSubCategories(){
        return this.orderSupportCategories.filter(category => category.parent_id === this.selectedSupportCategoryId)
      },
    	commentTextAreaPlaceholder() {
    		if (this.order && this.order.kim && this.order.kim.kimTitle === 'Freelancer'){
			    return 'Freelancer hakkındaki yorumlarını buraya yazabilirsin. Yorumun freelancer\'ın profilinde herkese açık bir şekilde yayınlanacaktır.';
		    } else {
    			return 'Alıcı hakkındaki yorumlarını buraya yazabilirsin. Yorumun alıcınının profilinde herkese açık bir şekilde yayınlanacaktır.';
        }
	    },
    },
    created() {
      this.orderSupportCategories = [];
      this.selectedSupportCategoryId = null;
      this.selectedSupportSubCategoryId = null;
      this.orderSupportTicketTitle = '';
      this.orderSupportExtraFieldValue = '';

      this.EventBus.$emit('closeConversationBar', true);

      this.supportTicketMessage = '';
		    this.supportFiles = [];
		    this.commentMessage = '';

      if (this.route.query.tab === 'support') {
        this.tab = 'support';
      } else {
	      this.tab = 'detail';
      }

	    this.EventBus.$on('goSupportPage', payload => {
		    this.tab = 'support';
		    window.scrollTo(0, 0);
	    });

      this.EventBus.$on('reloadOrderPage', payload => {
        if (payload === 'reload') {
          this.pageLoaded = false;
          this.getOrder(this.$store.state.routerParams[0]);
	        window.scrollTo(0, 0);
        }
      });
      this.getOrder(this.$store.state.routerParams[0]);

      for (let i = 1; i <= 90; i++) {
        this.orderSupportDaysArr.push({
          title:'+' + i.toString() + ' gün',
          id:i
        })
      }

      for (let i = 5; i <= 95; i=i+5) {
        this.orderSupportPercentageArr.push({
          title:'%' + i.toString() + '',
          id:i
        })
      }
    },
    beforeDestroy() {
      this.EventBus.$off('reloadOrderPage');
	    this.EventBus.$off('goSupportPage');
    }
  }

</script>



<style scoped lang="scss">



.rating-detail{
  margin-left:50px;visibility:hidden; position: absolute;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: -1px 13px 20px 0 #f0f2f4;
  z-index: 999;
  outline: none!important;

  &:focus{

    outline: none!important;

  }
  &:active{
    outline: none!important;

  }
  .rating-star-detail-title{
    font-size: 15px;
    color: #2d3640;
  }

}

  .slider-container{

    .rating-text{
      text-align: center; width: 185px; font-size: 42px; height: 52px; font-weight: bold; color: rgb(255, 191, 0); margin-top: 10px;
    }

    input[type=range] {
      /*removes default webkit styles*/
      -webkit-appearance: none;
      /*fix for FF unable to apply focus style bug */
      /*required for proper track sizing in FF*/
      width: 100%;
      height: 9px;
      padding: 0;
      border-radius: 5.5px;
      border: solid 1px #dfe4ec;
    }

    input[type=range]::-webkit-slider-runnable-track {
      width: 100%;
      height: 9px;
      border-radius: 5.5px;
      //background-color: #fa4359;
      border: 0;
    }

    input[type=range]::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 20px;
      width: 20px;
      border: 4px solid #FFF;
      border-radius: 50%;
      background: #ffbf00;
      margin-top: -6px;
      cursor: pointer;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 44, .5);
    }

    input[type=range]:focus {
      outline: none;
    }

    input[type=range]:focus::-webkit-slider-runnable-track {
      //background-color: #fa4359;
      cursor: pointer;
    }

    input[type=range]::-moz-range-track {
      width: 100%;
      height: 9px;
      border-radius: 5.5px;
      //background-color: #fa4359;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }

    input[type=range]::-moz-range-thumb {
      height: 12px;
      width: 12px;
      border: 4px solid #FFF;
      border-radius: 50%;
      background: #ffbf00;
      cursor: pointer;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 44, .5);
    }


    /*hide the outline behind the border*/

    input[type=range]:-moz-focusring {
      outline: none;
    }

    input[type=range]::-moz-focus-outer {
      border: 0;
    }

    input[type=range]::-ms-track {
      width: 100%;
      height: 5px;
      /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
      background: transparent;
      /*leave room for the larger thumb to overflow with a transparent border */
      border-color: transparent;
      border-width: 6px 0;
      /*remove default tick marks*/
      color: transparent;
      cursor: pointer;
    }

    input[type=range]::-ms-fill-lower {
      background: #777;
      border-radius: 10px;
    }

    input[type=range]::-ms-fill-upper {
      background: #ddd;
      border-radius: 10px;
    }

    input[type=range]::-ms-thumb {
      height: 20px;
      width: 20px;
      border: 4px solid #FFF;
      border-radius: 50%;
      background: #ffbf00;
      cursor: pointer;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 44, .5);
    }

    input[type=range]:focus::-ms-fill-lower {
      background: #888;
    }

    input[type=range]:focus::-ms-fill-upper {
      background: #ccc;
    }
  }



  .summary-comments-container{


    display: flex;
    align-items: center;
    justify-content: space-between;
    .summary-comment{
      width: 405px;
      height: 179px;
      border-radius: 10px;
      box-shadow: 0 0 0 0 rgba(45, 54, 64, 0.05);
      border: solid 1px #eaedf2;
      background-color: #ffffff;
    }



    .comment{
      font-size: 14px;
      font-weight: 300;
      line-height: 1.57;
      color: #5e6b79;
      margin-top: 10px;
      overflow-x: scroll;
      height: 110px;
    }
    .rating{
      font-size: 16px;
      font-weight: 600;
      color: #ffbf00;
      margin-left: 2px;
      padding-bottom: 2px;
    }
    .username{
      font-size: 16px;
      font-weight: 500;
      color: #4b4f52;
      margin-right: 7px;
    }

    .star-icon /deep/ {

      margin-top: 2px;
      width: 16px;
      height: 16px;
      path {
        fill: #ffbf00;
      }
    }
  }

  .order-files-containter{

    margin-top: 20px;
    display: flex;
    width: 841px;
    flex-wrap: wrap;
    justify-content: space-between;
    .order-file-flex{


      margin-bottom: 15px;

      .order-file{
        display: flex;
        align-items: center;
        justify-content: space-between;

        height: 63px;
        width: 403px;
        border-radius: 10px;
        box-shadow: 0 0 0 0 rgba(45, 54, 64, 0.05);
        border: solid 1px rgb(234, 237, 242);
        background-color: rgb(255, 255, 255);

        .download-icon-container{
          padding-right: 20px;
          cursor: pointer;
          &:hover{
            opacity: 0.7;
          }
        }
        .download-icon /deep/ {

          width: 18px;
          height: 16px;
          path {
            fill: #8b95a1;
          }
        }

        .icon_png{
          width: 21px;
          height: 27px;
        }
        .filename{
          font-size: 14px;
          font-weight: 500;
          color: #2d3640;
        }
        .filedate{
          font-size: 11px;
          color: #969fac;
        }
      }
    }

  }

  .tumu {
    cursor: pointer;
    font-size: 16px;
    font-weight: 400!important;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #00a575;
    padding-right: 10px;
    &:hover{
      text-decoration: underline;
    }
  }

  .edit-real-job-box{

    width: 843px;
    height: 153px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #ffffff;


    .text{
      font-size: 16px;
      font-weight: 300;
      line-height: 1.5;
      color: #5e6b79;
      margin-bottom: 30px;
      margin-top: 30px;
      margin-right: 30px;
      text-align: left;

      span{
        font-weight: bold;
        color: #00a575;
        cursor: pointer;
        &:hover{
          text-decoration: underline;
        }
      }
    }

    .icon-area{

      margin: 30px;
      display: flex; align-items: center;justify-content: center;
      width: 76px;
      height: 76px;
      border-radius: 50%;

      background-color: #5a26a4;
    }

    .portfolio-icon /deep/ {

      width: 32px;
      height: 35px;
      path {
        fill: #fff;
      }
    }

  }

  .order-reqs{
    width: 843px;
    min-height: 120px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px rgb(234, 237, 242);
    background-color: rgb(255, 255, 255);
  }

  .emoji-img {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 55px;
    top: 125px;
    cursor: pointer;
    z-index: 2;
    background-image: url('https://gcdn.bionluk.com/site/icon/smile/smile-icon.svg');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    background-size: contain;

    &:hover {
      background-image: url('https://gcdn.bionluk.com/site/icon/smile/smile-icon_hover.svg');
      width: 22px;
      height: 22px;
      right: 54px;
      top: 124px;
    }
  }

  .emoji-img-active {
    background-image: url('https://gcdn.bionluk.com/site/icon/smile/smile-icon_hover.svg') !important;
  }

  .emoji-picker {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px 0px;
    position: absolute;
    right: 50px;
    top: -160px;
    z-index: 3;
  }


  .text-button{
    color: #2d3640; font-size: 12px; font-weight: 500; cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }

  .activity-uploads{

    width: 543px;
    display: flex; justify-content: space-between; align-items:center; padding-top: 15px;padding-bottom: 15px;


    .icon-png{
      width: 21px;
      height: 27px;
    }
    .filename{

      font-size: 13px;
      font-weight: 500;
      color: #2d3640;
      margin-left: 10px;
      align-items: center;

      display: flex;
    }
    .download-icon-container{
      cursor: pointer;
      &:hover{
        opacity: 0.7;
      }
    }
    .download-icon /deep/ {

      width: 18px;
      height: 16px;
      path {
        fill: #2d3640;
      }
    }
  }

  .activity-message{
    margin-top: 20px; margin-bottom: 20px;  width: 543px;
    border-radius: 5px;
    background-color: rgb(255, 255, 255); display: flex; padding: 10px;

    .avatar-container{
      width: 36px;  margin-right:10px;align-self:flex-start;
    }

    .avatar{
      border: 1px solid #eff3f8;border-radius: 50%; width: 35px; height: 35px;
    }

    .message{
      color: #5e6b79; font-size: 12px;line-height: 1.67;
      overflow-wrap: anywhere;
    }
  }

  .line-text {
    width: 643px;
    text-align: center;
    border-bottom: 1px solid #eaedf2;
    line-height: 0.1em;
    margin: 0px auto 50px;

    span {
      background:#fff;
      padding:0 10px;
      font-size: 14px;
      color: #2d3640;
    }
  }

  .main-header{
    font-size: 20px;
    font-weight: 500;
    color: #2d3640;
    margin-bottom: 20px;
    span{
      font-weight: bold;
    }
  }



  .comment-box-container{
    text-align: left;
    margin-bottom: 48px;

    .comment-box{
      width: 843px;
      height: 693px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
      border: solid 1px #eaedf2;
      background-color: #ffffff;

    }

    .comment-box-header{
      width: 100%;
      height: 100px;
      border-bottom: 1px solid #eaedf2;
      p{
        padding: 27px 50px;
        font-size: 16px;
        font-weight: 300;
        line-height: 1.38;
        color: #2d3640;
        span{
          font-weight: bold;
        }
      }
    }
    .comment-box-body{
      height: 255px;
      width: 100%;
      background-color: rgba(244, 245, 247,0.5);
    }
    .comment-box-footer{
      height: 335px;
      width: 100%;
      border-top: 1px solid #eaedf2;
      padding: 25px;
    }
  }



  .order-activities{

    text-align: left;
    margin-top: 50px;
    margin-bottom: 48px;


    .white-content{
      width: 100%;

      border-radius: 10px;
      box-shadow: 0 0 10px 0 #2d36400c;
      border: solid 1px #eaedf2;
      background-color: #ffffff;
      padding: 30px 0 5px;




      .icon-rounded{
        position: absolute; margin-top: -22px;   display: flex; align-items: center;justify-content: center;
        width: 44px;
        height: 44px;
        border-radius: 50%;
      }

      .icon-svg /deep/ {
        width: 20px;
        height: 20px;
        path {
          fill: #ffffff;
        }
      }


      .activity{
        border-radius: 10px;

        padding-bottom: 15px;
        display: flex; align-items: center; flex-direction: column;
        max-width: 643px;
        margin:0 auto;


        .header-text{
          font-size: 16px;
          font-weight: 600;
          color: #343f4a;
          margin-top: 38px;
          text-align: center;
        }

        .text{

          font-size: 14px;
          line-height: 1.57;
          color: #5e6b79;
          margin-top: 5px;
          max-width: 543px;
          text-align: center;
        }

        .sub-header-text{

          font-weight: bold;
          font-size: 14px;
          line-height: 1.57;
          color: #5e6b79;
          margin-top: 5px;
          max-width: 543px;
          margin-top: 5px;
        }

      }

      .activity-for{
        margin-bottom: 25px;


        .time{

          margin-top: 5px;
          font-size: 14px;
          font-weight: 500;
          text-align: center;
          color: #969fac;
        }

      }

    }
  }



  .delete-icon /deep/ {
    cursor: pointer;
    width: 14px;
    height: 14px;
    path {
      fill: #8b95a1;
    }
  }

  .attach-icon /deep/ {
    width: 14px;
    height: 14px;
    path {
      fill: #2d3640;
    }
  }

  .ticket-message-area{



    margin-bottom: 100px;
    .avatar{
      width: 43px;
      height: 43px;
      border-radius: 50%;
      border: 1px solid #eff3f8;
    }



    .ticket-message{
      margin-top: 50px;
      display: flex;
      width: 100%;

    }




      .bubble-right{
         div{


           line-height: 1.86;
           color: #2d3740;
           max-width: 638px;
           padding: 15px 23px;
           border-radius: 10px;
           border-bottom-left-radius: 0;

           background-color: #e4e8ed;
           text-align: left;
         }
        p{
          margin-top: 8px;
          text-align: left;
          font-size: 12px;
          color: #8b95a1;
        }

      }
      .bubble-left{

        div{

          line-height: 1.86;
          color: #2d3740;
          max-width: 638px;
          padding: 15px 23px;
          border-radius: 10px;
          border-bottom-right-radius: 0;
          text-align: left;
          background-color: rgba(163, 224, 225,0.5);
        }

        p{
          margin-top: 8px;
          text-align: right;
          font-size: 12px;
          color: #8b95a1;
        }


      }
      .bubble-bi{

        div{
          background-color: rgba(253, 64, 86, 0.1);

          line-height: 1.86;
          color: #2d3740;
          max-width: 638px;
          padding: 15px 23px;
          border-radius: 10px;
          border-bottom-left-radius: 0;
          text-align: left;
        }

        p{
          margin-top: 8px;
          text-align: left;
          font-size: 12px;
          color: #8b95a1;
        }


      }



  }

  .res-center-head-box{
    width: 743px; margin: 40px auto 0;  display: flex;  justify-content: center;
    height: 172px;
    border-radius: 10px;
    background-color: #e4e8ed;

    .icon-area{
      position: absolute; margin-top: -27px; width: 56px;  display: flex; align-items: center;justify-content: center;
      height: 56px;
      border-radius: 50%;
      border: solid 1px #eaeaea;
      background-color: #fd4056;
    }

    .text1{
      margin-top: 40px; color:#343f4a; font-size: 18px; font-weight: 600;
    }

    .text2{
      color: #2d3740; line-height: 1.63; font-size: 16px; margin-top: 10px; max-width:530px;
    }


    .resolution-center-icon /deep/ {
      width: 30px;
      height: 30px;
      path {
        fill: #ffffff;
      }
    }
  }


  .backo{
    color: #fe5b5c;
    font-size: 12px;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }

  .res-center-center{
    width: 95%; margin: 40px auto;
    border-radius: 5px;
    background-color: #fff;
    text-align: left;
    padding-bottom:20px;



    .text1{
      padding-left:30px;
      padding-top: 30px;
      color:#343f4a; font-size: 18px; font-weight: 600;
    }


    .row-center{
      margin-top: 20px;
      margin-left: 35px;
      display: flex;
      align-items: center;
      .big-radio{
        cursor: pointer;
        -ms-transform: scale(1.5); /* IE 9 */
        -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
        transform: scale(1.5);
      }


      label{
        cursor: pointer;
        margin-left: 15px;
        font-size: 14px;
        padding-top: 4px;
        color: #5e6b79;
        font-weight: 300;
      }
    }

  }
  .tabs{

    cursor: pointer;
    display: flex; height: 70px; width: 843px;

    .tab-bar{
      width: 422px;
      height: 67px;
      border-bottom: 3px solid #e6e9ee;
      background-color: #e6e9ee;
      display: flex;
      align-items: center;
      justify-content: center;
      .text{
        font-size: 18px;
        font-weight: 500;
        color: #8b95a1;

      }

      &:hover{
        .text{
          font-size: 18px;
          font-weight: 600;
          color: #3c4654;

        }
      }
    }


    .tab-bar-active{
      width: 422px;
      height: 67px;
      background-color: #ffffff;
      border-bottom: 3px solid #2d3640;
      display: flex;
      align-items: center;
      justify-content: center;

      .text{
        font-size: 18px;
        font-weight: 600;
        color: #3c4654;

      }
    }

  }


  .datetime{
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #8b95a1;
  }


</style>
